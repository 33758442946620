import { lazy, useEffect, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';

import '@bluecodecom/fonts';

import { nativeService } from '@bluecodecom/common/services/native';

import './i18n';

const App = lazy(() => import('./app'));

const CONTENT_DELAY_MS = 750;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fbq?: any;
  }
}

const Main = () => {
  const consumerScanCode = useMemo(() => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get('consumer_scan_code') || '';
    } catch {
      // fallthrough
    }
    return '';
  }, []);

  const externalPaymentProvider = useMemo(() => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get('external_payment_provider') || '';
    } catch {
      // fallthrough
    }
    return '';
  }, []);

  const unsupportedSdk = useMemo(() => {
    return nativeService.getSdkVersion() === 'not new_webview';
  }, []);

  const [hideContent, setHideContent] = useState(
    !externalPaymentProvider && !unsupportedSdk && !!consumerScanCode,
  );

  useEffect(() => {
    if (unsupportedSdk) {
      return;
    }

    if (!consumerScanCode) {
      return;
    }

    if (externalPaymentProvider) {
      return;
    }

    // Append ts param with timestamp to prevent webview cache on sdk
    // const idx = consumerScanCode.indexOf('&');
    // const csWithTsParts = consumerScanCode.split('');
    // const ts = encodeURIComponent(`&ts=${Date.now()}`);
    // csWithTsParts.splice(idx, 0, ts);

    // const csWithTs = csWithTsParts.join('');

    nativeService.initiatePayment({
      ecom_id: consumerScanCode,
      'x-success': consumerScanCode,
      'x-cancel': consumerScanCode,
      'x-error': consumerScanCode,
    });

    setTimeout(setHideContent, CONTENT_DELAY_MS, false);
  }, [consumerScanCode, externalPaymentProvider, unsupportedSdk]);

  if (hideContent) {
    return null;
  }

  return <App />;
};

createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
